import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: '/',
      name: 'home',
      component: () =>
        import('../page/home/index.vue'),
    },
    {
      path: '/234013/productREC',
      name: "productREC",
      component: () =>
        import('../page/productREC/index.vue'),
    },
    {
      path: '/product-details',
      name: 'product-details',
      component: () => import('../page/product-details/index.vue'),
      meta: {
        title: 'Product details',
      },
    },
    {
      path: '/recommend',
      name: 'recommend',
      component: () => import('../page/recommend/index.vue'),
      meta: {
        title: 'Recommend',
      },
    },
    {
      path: '/new-arrivals',
      name: 'newArrivals',
      component: () => import('../page/new-arrivals/index.vue'),
      meta: {
        title: 'New Arrivals',
      },
    },
    {
      path: '/almost-sold',
      name: 'almostSold',
      component: () => import('../page/almost-sold/index.vue'),
      meta: {
        title: 'Almost Sold',
      },
    },
    {
      path: '/special-recommendations',
      name: 'special-recommendations',
      component: () => import('../page/special-recommendations/index.vue'),
      meta: {
        title: 'Special Recommendations',
      },
    },
    {
      path: '/my',
      name: 'my',
      component: () => import('../page/my/index.vue'),
      meta: {
        title: 'My',
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('../page/orders/index.vue'),
      meta: {
        title: 'My Orders',
      },
    },
    {
      path: '/commission',
      name: 'commission',
      component: () => import('../page/commission/index.vue'),
      meta: {
        title: 'My team detail',
      },
    },
    {
      path: '/sale',
      name: 'sale',
      component: () => import('../page/sale/index.vue'),
      meta: {
        title: 'My goods on sale',
      },
    },
    {
      path: '/chat',
      name: 'chat',
      component: () => import('../page/chat/index.vue')
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: () => import('../page/checkout/index.vue')
    },
    {
      path: '/withdrawal',
      name: 'withdrawal',
      component: () => import('../page/withdrawal/index.vue')
    },
    {
      path: '/withdrawal-detail',
      name: 'withdrawal-detail',
      component: () => import('../page/withdrawal-detail/index.vue')
    },
    {
      path: '/address',
      name: 'address',
      component: () => import('../page/address/index.vue')
    },
    {
      path: '/add-dddress',
      name: 'add-dddress',
      component: () => import('../page/add-dddress/index.vue')
    },
    {
      path: '/result',
      name: 'result',
      component: () => import('../page/result/index.vue')
    },
    {
      path: '/vip',
      name: 'vip',
      component: () => import('../page/vip/index.vue')
    },
    {
      path: '/detail-earnings',
      name: 'detail-earnings',
      component: () => import('../page/detail-earnings/index.vue')
    },
    {
      path: '/set',
      name: 'set',
      component: () => import('../page/set/index.vue')
    },
    {
      path: '/commission-rules',
      name: 'commission-rules',
      component: () => import('../page/commission-rules/index.vue')
    },
    {
      path: '/add-bank',
      name: 'add-bank',
      component: () => import('../page/add-bank/index.vue')
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('../page/search/index.vue')
    },
    {
      path: '/qa',
      name: 'qa',
      component: () => import('../page/qa/index.vue')
    },
    {
      path: '/vip/account',
      name: 'vip-account',
      component: () => import('../page-vip/account/index.vue')
    },
    {
      path: '/vip/spread',
      name: 'vip-spread',
      component: () => import('../page-vip/spread/index.vue')
    },
    {
      path: '/vip/square',
      name: 'vip-square',
      component: () => import('../page-vip/square/index.vue')
    },
    {
      path: '/vip/information',
      name: 'vip-information',
      component: () => import('../page-vip/information/index.vue')
    },
    {
      path: '/vip/commission',
      name: 'vip-commission',
      component: () => import('../page-vip/commission/index.vue')
    },
    {
      path: '/vip/history',
      name: 'vip-history',
      component: () => import('../page-vip/history/index.vue')
    },
    {
      path: '/vip/rewards',
      name: 'vip-rewards',
      component: () => import('../page-vip/rewards/index.vue')
    },
    {
      path: '/vip/withdrawal',
      name: 'vip-withdrawal',
      component: () => import('../page-vip/withdrawal/index.vue')
    },
    {
      path: '/vip/rules',
      name: 'vip-rules',
      component: () => import('../page-vip/rules/index.vue')
    }
  ]
});

export default router;